/* Generated by Glyphter (http://www.glyphter.com) on  Mon Jun 26 2023*/
@font-face {
	font-family: 'icons';
	src: url('/public/fonts/icons/icons.eot');
	src: url('/public/fonts/icons/icons.eot?#iefix') format('embedded-opentype'),
			 url('/public/fonts/icons/icons.woff') format('woff'),
			 url('/public/fonts/icons/icons.ttf') format('truetype'),
			 url('/public/fonts/icons/icons.svg#icons') format('svg');
	font-weight: normal;
	font-style: normal;
}
[class*='icon-']:before{
display: inline-block;
 font-family: 'icons';
 font-style: normal;
 font-weight: normal;
 line-height: 1;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale
}
.icon-discord:before{content:'\0043';}
.icon-5968732:before{content:'\0045';}
.icon-menu:before{content:'\0046';}
.icon-attention:before{content:'\0047';}
.icon-chat:before{content:'\0048';}
.icon-chat1:before{content:'\0049';}
.icon-shield:before{content:'\004a';}
.icon-star:before{content:'\004b';}
.icon-search:before{content:'\004c';}
.icon-tag:before{content:'\004d';}
.icon-bell:before{content:'\004e';}
.icon-banner:before{content:'\004f';}
.icon-menu1:before{content:'\0050';}
.icon-info:before{content:'\0051';}
.icon-check:before{content:'\0052';}
.icon-question:before{content:'\0053';}
.icon-settings:before{content:'\0054';}
.icon-right:before{content:'\0055';}
.icon-left:before{content:'\0056';}
.icon-up:before{content:'\0057';}
.icon-down:before{content:'\0058';}
.icon-heart:before{content:'\0059';}
.icon-circle:before{content:'\005a';}
.icon-left1:before{content:'\0061';}
.icon-right1:before{content:'\0062';}
.icon-up1:before{content:'\0063';}
.icon-down1:before{content:'\0064';}